let didReadProductData = false;

// Default values
let productData = {
  entityType: 'product'
};

export const getProductDataFromHTML = () => {
  const productDataWrapper = document.getElementById('product-data');

  if (!productDataWrapper) {
    console.warn(
      'Could not read product data from DOM, missing #product-data element'
    );
    return productData;
  }

  if (didReadProductData) return productData;

  // Map dataset from DOM with default values
  productData = {
    ...productData,
    ...productDataWrapper.dataset
  };

  didReadProductData = true;

  return productData;
};
